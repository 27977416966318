#root {
  height: 100%;
}
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.rrr {
  width: 540px !important;
  display: block !important;
  // margin-bottom: 0.7rem;
}
.form-label > .myCustom b {
  color: red !important;
}