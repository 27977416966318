/** colors */

// backgrounds
$color-white-primary: #fff;
$color-white-secondary: #eae8e6;

// fonts
$color-black-primary: #333;
$color-black-secondary: #625548;

// backgrounds & highlights
$color-violet-primary: #276cda;
$color-violet-secondary: #4883e0;
$color-violet-ternary: #6491d8;

// buttons
$color-green-primary: #00c0ac;
$color-green-secondary: #02a695;

// backgrounds & highlights
$color-yellow-primary: #ee7700;
$color-gray-primary: #aba79d;

/** fonts */
$font-myriadpro-filename: 'MyriadPro-Regular.otf';
$font-myriadpro-primary: 'MyriadPro-Regular';
$font-blockberth-filename: 'BlockBertholdTextured.6de10b04.ttf';
$font-blockberth-primary: 'BlockBertholdTextured';
$font-dinnextltpro-filename: 'DINNextLTPro-Regular.ffa48a29.woff';
$font-dinnextltpro-primary: 'DINNextLTPro-Regular';

/** assets path */
$asset-font-path: '../assets/fonts/';
