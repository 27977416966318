// font families
@font-face {
  font-family: $font-myriadpro-primary;
  src: url($asset-font-path+$font-myriadpro-filename) format('opentype');
}

@font-face {
  font-family: $font-blockberth-primary;
  src: url($asset-font-path+$font-blockberth-filename) format('truetype');
}

@font-face {
  font-family: $font-dinnextltpro-primary;
  src: url($asset-font-path+$font-dinnextltpro-filename) format('woff');
}
