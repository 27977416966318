@import 'stylesheets/variables.scss';
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: $font-dinnextltpro-primary !important;
}
html,
body {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-blockberth-primary;
}

/* breakpoints 
  320px - 720px - For mobile.
  721px - 1023px - For tab.
  1024px - 1440px - For Desktop.
*/
@media all and (min-width: 1600px) {
  // define your rem value
}

@media all and (min-width: 1200px) {
  // define your rem value
}

@media all and (min-width: 992px) {
  // define your rem value
}

@media all and (min-width: 768px) {
  // define your rem value
}

@media all and (min-width: 576px) {
  // define your rem value
}

@media all and (max-width: 575px) {
  // define your rem value
}

/* breakpoints - retina */
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (min-resolution: 1.5dppx) {
  // define your rem value
}
